.container.body-container {
    min-height: 60vh;
    width: 100%;
    // background-image: url(../../Data/Images/background.jpg);
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
};

.fixed-top {
    border-radius: 0 10px 10px 0;
    position: fixed;
    background-color: white;
    z-index: 50;
}

.remove-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.right {
    display: flex;
    justify-content: flex-end;
}

.filter-button {
    background-color: white;
    color: black;
    border: black;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: roboto;
    border-bottom: 1px solid black;
}

.filter-button-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pagnation-buttons{
  position: fixed;
  bottom: 0px;
  right: 12px;
}

.full-width {
  width: 100%;
}

.post-margin {
    margin-top: 38vh;
}

.grid-container-posts {
    padding: 10px;
    padding-bottom: 50px;
    display: grid;
    gap: 10px;
    // grid-auto-rows: 170px;
    grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
}

.price-background {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 20px;
  font-weight:400;
  // border: 1px solid black;
  font-family: Helvetica;
}

// @media screen and (max-width:450px) {
//   .grid-container-posts {
//     grid-auto-rows: 200px;
//   }
// }

// @media screen and (min-width: 0px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(2, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 450px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(3, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 520px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(4, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 700px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(5, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 850px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(6, 1fr);
//     }
//   }
//   @media screen and (min-width: 950px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(7, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 1100px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(8, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 1200px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(9, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 1300px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(10, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 1400px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(11, 1fr);
//     }
//   }
  
//   @media screen and (min-width: 1500px) {
//     .grid-container-posts {
//       grid-template-columns: repeat(12, 1fr);
//     }
//   }
