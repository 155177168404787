.modal-background-paypal {
    // border: 2px solid black;
    border-radius: 4px;
    background-color: white;
    padding: 0px 20px 20px 20px;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
}

#paypal-currency-selector {
    text-align: center;
    background: #EFEFEF;
    color: black;
    width: 100%;
    border: none;
    margin-bottom: 15px;
    border-radius: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 20px;
}

#paypal-currency-selector:hover {
    filter: brightness(0.95);
    cursor: pointer;
}

#paypal-exit-button {
    position: sticky;
    display: flex;
    z-index: 100;
    left: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
}

.donate-title {
    font-size: 2.5rem;    
}

.edit-modal .modal-background-paypal fieldset {
    border: none;
}

#paypal-amount-input {
    text-align: center;
    background: #EFEFEF;
    color: black;
    width: 100%;
    max-height: 22px;
    border: none;
    margin-bottom: 15px;
    border-radius: 4px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
}