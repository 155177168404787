

.filter-item {
    padding: 5px;
    font-family: open sans;
}

.reset-button {
    width: 100%;
}

.price-input {
    width: 100%;
}

.select-width {
    width: 100%;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.filter-content {
    padding-left: 40px;
    padding-right: 40px;
}

.filter-container {
    border-bottom: 1px solid black;
    border-top: 1px solid black !important;
    border-radius: 0px;
    border-top: none;
    display: grid;
    background-color: white;
    padding: 20px 30px 20px 30px;

}

.filter-exit-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.bottom-margin {
    margin-bottom: 5px;
}

.dropdown {
    padding-bottom: 15px;
}