.info-label {
    text-align: right;
    padding-right: 0px;
}

.info-value {
    text-align: left;
    padding-left: 0px;
    margin-bottom: 15px;
}

.horizontal-center {
    display: flex;
    justify-content: center;
}

.info-container{
    padding: 20px 20px 20px 20px;
    border-radius: 1px;
    max-width: 600px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 8px;
}

.traverse-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

#traverse-prev-button {
    font-size: 28px;
}

#traverse-next-button {
    font-size: 28px;
}

.content-container {
    display:flex;
    justify-content: center;
}

.image-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    border: 1px solid black;
    border-radius: 20px;
    min-height: 650px;
    max-width: 500px;
    background-repeat: none;
    background-size: 100% 100%;
    @media only screen and (max-width: 600px){
        min-height: 450px;
    }
}

.select-image-container {
    margin-bottom: 15px;
    border-radius: 1px;
    min-height: 300px;
    max-width: 250px;
    background-repeat: none;
    background-size: 100% 100%;
    // @media only screen and (max-width: 600px){
    //     min-height: 300px;
    //     max-width: 250px;
    // }
}

.modal-background {
    // box-shadow: rgba(0, 0, 0, 0.08) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.07) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.05) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.03) 0px 2px 1px, rgba(0, 0, 0, 0.045) 0px 4px 2px, rgba(0, 0, 0, 0.045) 0px 8px 4px, rgba(0, 0, 0, 0.045) 0px 16px 8px, rgba(0, 0, 0, 0.045) 0px 32px 16px;
    position:relative;
    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    width: 88vw;
    max-width: 700px;
    max-height: 95vh;
    border-radius: 1px;
    background-color: white;
    @media (pointer:none), (pointer:coarse) {
        max-height: 85vh;
        height: 85vh;
    }
}

.select-modal {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus-visible{
        outline: none;
    }
}

.center-start {
    margin: 0;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
}

#select-modal-exit-button:hover {
    color: red;
} 
#select-modal-exit-button{
    position: absolute;
    right: 0;
    top: 0;
    font-size: 26px;
}

#select-modal-title{
    font-size: 46px;
}

.selected-post-image-container {
    // margin: 30px;
}

.display-title {
    h1 {
        font-size: 35px;
        font-weight: 500;
    }
}

.brand-title {
    h3 {
        font-size: 20px;
        font-weight: 500;
    }
}

.date-created {
    font-size: 15px;
    color: black;
}

.condition-text { 
    color: grey;
    font-size: 15px;
}

.description-wrapper {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 400;
}

.multi-content {
    display: flex;
    justify-content: space-evenly;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    border-left: 1px solid black;
    background-color: lightgrey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.4); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0,0,0,0.2); 
}

.selling-price-wrapper .br {
    color: rgb(236, 84, 249);
}

.selling-price-wrapper .bg {
   color: rgb(124, 210, 25);
}

.link-wrapper {
    display: flex;
    justify-content: center;
}

.user-profile-selected-post{
    padding-top: 25px;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.link .account-profile {
    width: 70px;
    height: 70px;
    border-radius: 1px;
    @media only screen and (max-width: 600px){
        width: 60px;
        height: 60px;
    }
}

.link-wrapper a {
    width: 0px;
    height: 0px;
}

.display-name {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.275rem;
    letter-spacing: 0.02857em;
    color:#1976d2;
}

.profile-link {
    @media (pointer:none), (pointer:coarse) {
        padding-top: 20px;
    }
}

.selling-price-wrapper {
    margin: 0px;
    font-size: 1.675rem;
    letter-spacing: 0.02857em;
    font-weight: 500;
    height: auto;
    border-radius: 15px;
    @media (pointer:none), (pointer:coarse) {
        font-size: 2.075rem;
    }
}

.selling-price-wrapper .desciption-title{
    font-size: 15px;
}

.created {
    margin-top: 15px;
}

.all-pictures {
    display: grid;
    grid-template-columns: repeat(auto-fit,50px);
    grid-gap: 0.25rem;
    justify-content: center;
    margin-bottom: 10px;
}

.individual-picture {
    cursor: pointer;
}

.contact {
    margin: 10px;
}

.carousel-post {
    display:flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    position: absolute;
    height: 87%;
    max-height: 95vh;
    top: 6.5%;
    z-index: 100;
    border-radius: 1px;
    color:white;
    cursor: pointer;
    &.next {
        right: 0px;
    }
    &.prev {
        left: 0px;
    }
    &.buy {
        background-color: rgb(91, 226, 60);
    }
    &.sell {
        background-color: rgb(236, 84, 249);
    }
    &.trade {
        background-color: rgb(55, 244, 251);
    }
    &:hover{
        width: 35px;
    }

    @media only screen and (min-width: 575px) {
        height: 53%;
        top: 23.5%;
    }
}

.vanish-left {
    animation: vanish-left 1s;
    animation-fill-mode: forwards;
}

.vanish-right {
    animation: vanish-right 1s;
    animation-fill-mode: forwards;
}

@keyframes vanish-right{
    0%{
        opacity: 1;
        left: 0px;
    }
    50% {
        left: 1000px;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}

@keyframes vanish-left{
    0%{
        opacity: 1;
        right: 0px;
    }
    50%{
        right: 1000px;
    }
    80%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
}