.login-header-container {
    background-color: #0b5ed7;
    color: white;
    width: 100%;
    height: 100px;
}

.login-header {
    color: white;
    padding: 35px 10vw 10vw 10vw;
}

.require-login-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}