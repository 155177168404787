.App-header {
  background-color: white;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 1px solid rgba(rgb(25, 118, 210), 1);
  // box-shadow: 0 1px 2px rgb(0 0 0 / 13%), 0 0px 2px rgb(0 0 0 / 8%);
  // box-shadow: rgba(#0d6efd, 0.25) 0px 1px 3px -2px, rgba(#0d6efd, 0.3) 0px 3px 7px -3px;
}

.between {
  justify-content: space-between;
}

.container{
  margin-left: 0px;
  margin-right: 0px;
  min-width: 100%;
}

.remove-right-padding {
  padding-right: 0px;
}

.pointer {
  cursor: pointer;
  &:hover {
    color: blue;
  }
}

.popover-container{
  padding: 10px;
}

.popover-language-container {
  padding: 10px;
  max-height: 300px;
  max-width: 154px;
  overflow-y: scroll;
}

.popover-language-container * {
  text-transform: none;
}

.login-container button {
  min-width: 40px !important;

}
.account-dropdown button {
  width: 100%;
  position: flex;
  justify-content: flex-start;
}

.popover-language-container::-webkit-scrollbar {
  display: none;
}

.center {
  text-align: center;
}

.icon-padding {
  padding-top: 5px;
}

.right {
  text-align: right;
}

.nav-bar {
  margin-left: 0;
  background-color: red;
  font-size:20px;
}

.title {
  font-size: 40px;
  color: red;
}

@media screen and (max-width: 700px) {
  .title {
    display: none;
  }

  .login-container-left button {
    min-width: 40px;
  }

  .login-container-right button {
    min-width: 40px;
  }
}

.white {
  color: white;
}

.account-dropdown {
  padding: 0 10px 0 10px;  
}

.search-results-container {
  padding: 10px;
  min-width: 250px;
  max-height: 190px;
  overflow-y: auto;
}

.left {
  text-align: left;
}

.visible {
  opacity: 1;
  visibility: visible;
  display: block;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  display: none;
}

.account-profile {
  background-size: 100% 100%;
  width: 40px;
  height: 40px;
  // border: .01em solid rgba(0,0,0,.2);
  // background-repeat: no-repeat;
  // background-position: center;
  // box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
}

.user-account-profile {
  background-size: 100% 100%;
  width: 200px;
  height: 200px;
  border-radius: 2vw;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
}

.nav-bar-item a{
  height: 65px;
}

.user-account-profile-col {
  padding-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -30px 36px -28px inset, rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.contact-outer-container {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -30px 36px -28px inset, rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em;
  // min-height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-media-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  .item {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5px;
  }
}

.profile-header{
  color: white;
  padding: 35px 10vw 10vw 10vw;
}

.profile-header-container{
  background-color: #0b5ed7;
  color: white;
  width: 100%;
  height: 100px;
}

.profile-image {
  margin: auto;
}

.user-profile-container {
  // margin-top: 20px;
}

input[type="search"] {
  padding: 10px 3px 10px 10px;
  width: 350px;

}

label[data-shrink="false"]{
  transform: translate(14px, 11px) scale(1);
}

.popover-item {
  border: 1px solid black;
  &:hover {
    background-color: rgba(#1976d2, .3)
  }
}

.report-vouch-buttons {
  width: 95%;
  position: absolute;
  bottom: 0;
  margin-right: auto;
  margin-left: auto;
}

.map-col-container {
  // box-shadow: rgba(0, 0, 0, 0.05) 0px -50px 36px -28px inset, rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em;
  padding-top: 5px;
  // height: 250px;
}

.map-container {
  display: flex;
  justify-content: center;
  // padding: 0px 30px 30px 30px;
  max-height: 700px;
}

.map-container>* {
  width: 400px;
  height: 200px;
  border-radius: 2vw;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}


.info-dropdown {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  h2 {
      cursor: pointer;
  }
}

.loc-pin {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: lightgrey;
  font-size: 16px;
}

.user-full-name {
  flex: 0 0 auto;
}

.user-full-name-loc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.setting-icon {
  margin-bottom: 5px;
}

.popover-text {
  color: black;
}

.login-container-left {
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.login-container-right {
  margin-right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.popover-container button {
  text-transform: none;
}

.login-container-left button {
  text-transform: none;
}

hr {
  margin: 10;
}

.menu-icon {
  display: flex;
  align-items: center;
}

.language-title {
  border-bottom: 1px solid black;
}

.post-link {
  cursor: pointer;
  color: blue;
}